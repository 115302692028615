import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logoWithText from "./images/logoWithText.png";
import sharedImg from "./images/shared-img.png";
import ImageDownloadButton from "./ImageDownloadButton";
import "./App.css";

const App = () => {
  const imageUrl =
    "https://apinew.sprocketprinters.com/api/download-img?key=64cb418be6cc3ab9/2/temp-single/01HKS6Q7XSY9YNYVCXE2T7X4K5.jpg";
  const [token, setToken] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the token value from the 'token' parameter
    const tokenFromUrl = urlParams.get("token");

    // Set the token in the state
    // setToken(tokenFromUrl);

    // If you want to make the API call immediately, you can do it here
    if (tokenFromUrl) {
      fetchData(tokenFromUrl);
    }

    
  }, []);

  const fetchData = async (token) => {
    // console.log("token::", token)
    setLoader(true);
    try {
      // Make an API call with the token
      const response = await fetch(
        `https://apinew.sprocketprinters.com/image_data?token=${token}`
      );
      const data = await response.json();

      // Set the response data in the state
      setImageData(data);

       // Extract image name from imageUrl and set it in state
       const imageNameFromUrl = getImageNameFromUrl(data.image_path);
       setImageName(imageNameFromUrl);

      // Log the response data (you might want to do something else with it)
      //   console.log('API Response:', data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set loader to false after API call, regardless of success or failure
      setLoader(false);
    }
  };

  const getImageNameFromUrl = (url) => {
    // Split the URL by '/' and take the last part
    const parts = url.split('/');
    return parts[parts.length - 1];
  };


  //   console.log("imageData:::", imageData.image_path)

  return (
    <>
      {loader && (
        <div className="overlay" id="spinner">
          <div className="overlay__inner">
            <div className="overlay__content">
              <img src="https://kodak-media-processed.s3.us-east-2.amazonaws.com/master/SpinnerWebAnimation.gif" />
            </div>
          </div>
        </div>
      )}

      <div className="wrapper">
        <div className="center-photo-popup">
        <div class="share-photo-header hp-header">
                <img 
                // src={sharedImg}
                src="https://kodak-media-processed.s3.us-east-2.amazonaws.com/master/hp-logo-2.png" 
                width="56"
                    height="56"/>
                <h2>Your photo is ready!</h2>
            </div>
          <div className="common center1">
            <div className="parent">
              <div className="child center-text">
                <div className="center-text-inner">
                  {loader && 
                    <div className="overlay" id="spinner">
                      <div className="overlay__inner">
                        <div className="overlay__content">
                          <img src="https://kodak-media-processed.s3.us-east-2.amazonaws.com/master/SpinnerWebAnimation.gif" />
                        </div>
                      </div>
                    </div>}
                  
                    <img
                      src={imageData && imageData.image_path}
                      // src={sharedImg}
                      width=""
                      height=""
                      alt="shared image"
                    />
                  
                  {/* <h2>Title</h2> */}
                </div>
                <ImageDownloadButton
                  // imageUrl={sharedImg}
                  imageUrl={imageData && imageData.image_path}
                  imageName={imageName}
                  isLoading={loader}
                  setIsLoading={setLoader}
                />
                <span>Links expires {imageData && imageData.expiry}</span>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* <div class="wrapper" id="main_div">
        <div class="center-photo-popup">
            <div class="share-photo-header hp-header">
                <img src="https://kodak-media-processed.s3.us-east-2.amazonaws.com/master/hp-logo-2.png" width="56"
                    height="56">
                <h2>Your photo is ready!</h2>
            </div>
            <div class="common center1">
                <div class="parent">
                    <div class="child center-text">
                        <div class="center-text-inner">
                            <img src="<%=image_path%>" width="" height="" />
                            <!-- <h2>Title</h2> -->
                        </div>
                        <button type="button" class="btn btn-primary save-btn hp-download-btn"
                            id="download-link">Download</button>
                        <span class="link-expire">Link expires <%=expiry%></span>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </>
  );
};

export default App;
