import React, { useState } from "react";
import "./ImageDownloadButton.css"; // Import the CSS file for styling

const ImageDownloadButton = ({
  imageUrl,
  imageName,
  forceDownload = false,
  isLoading,
  setIsLoading
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  // const imageUrl = 'path/to/your/image.jpg'; // Replace with your actual image URL

  const handleDownload = async () => {
    setIsLoading(true);

    try {
        // if(!forceDownload){
        //   const imgBlob = await fetch(imageUrl)
        //   .then((response) => response.arrayBuffer())
        //   .then((buffer) => new Blob([buffer], { type: "image/jpg" }));
        //     const link = document.createElement("a");
        //     link.href = URL.createObjectURL(imgBlob);;
        //     link.download = imageName;
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        //   }

      const imgBlob = await fetch(imageUrl)
        .then((response) => response.arrayBuffer())
        .then((buffer) => new Blob([buffer], { type: "image/jpg" }));


      const link = document.createElement("a");
      link.href = imageUrl
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadComplete(true);
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={handleDownload}
        disabled={isLoading}
        type="button"
        className="btn btn-primary save-btn hp-download-btn"
        id="download-link"
      >
        {isLoading ? (
          <div className="overlay" id="spinner">
            <div className="overlay__inner">
              <div className="overlay__content">
                <img src="https://kodak-media-processed.s3.us-east-2.amazonaws.com/master/SpinnerWebAnimation.gif" />
              </div>
            </div>
          </div>
        ) : (
          "Download"
        )}
      </button>
      {/* {downloadComplete && <p>Image downloaded successfully!</p>} */}
      {/* {downloadComplete && (
        <div className="download-animation">
        </div>
      )} */}
    </div>
  );
};

export default ImageDownloadButton;
